<template>
  <PrimePage titleAlignment="left" :loading="isLoading" :backToNamedRoute="'ProgramDashboard'" :backText="'Back to Outreaches Overview'">
    <ion-row v-if="isLoading" class="text-center pad-ten full-height">
      <div class="centerItems text-muted display-flex valign full-height full-width">
        <ion-spinner name="lines" class="spinner-large valign"></ion-spinner>
      </div>
    </ion-row>
    <ion-content v-else-if="currentPRTTask" class="prime-ion-content-task-details">
      <!-- {{ currentPRTTask }} -->
      <ion-row class="margin-top-twenty pad-lr-ten valign display-flex">
        <ion-col class="pad-lr-ten">
          <h2>Task ID {{ currentPRTTask.id }}</h2>
        </ion-col>
        <ion-col></ion-col>
        <ion-col size="auto" class="pad-ten margin">
          <button @click="getTask(false, false, false, 'prevTask')" class="prime-button button-secondary"><ion-icon name="ios-arrow-back"></ion-icon> Previous Task</button>
        </ion-col>
        <ion-col size="auto" class="pad-ten margin">
          <button @click="getTask(false, false, false, 'prevOpenTask')" class="prime-button button-secondary"><IconChevronDouble class="icon-small" /> Previous Open Task</button>
        </ion-col>
        <ion-col size="auto" class="pad-ten margin">
          <button @click="getTask(false, false, false, 'nextOpenTask')" class="prime-button button-primary">Next Open Task <IconChevronDoubleBack class="icon-small" /></button>
        </ion-col>
        <ion-col size="auto" class="pad-ten margin">
          <button @click="getTask(false, false, false, 'nextTask')" class="prime-button button-primary">Next Task <ion-icon name="ios-arrow-forward" class="icon-forward"></ion-icon></button>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-card color="white">
            <div class="pad-ten">
              <ion-row v-if="currentPRTTask.patient && currentPRTTask.provider">
                <ion-col class="valign" size="auto">
                  <h5>{{ makeName }}</h5>
                </ion-col>
                <ion-col></ion-col>
                <ion-col size="auto" class="pad-lr-twenty">
                  <div class="text-muted">
                    Provider:
                  </div>
                  <h6>{{ makePcpName }}</h6>
                </ion-col>
                <ion-col size="auto" class="pad-lr-twenty">
                  <div class="text-muted">
                    Date of Birth:
                  </div>
                  <h6 v-if="currentPRTTask.patient.dob">{{ currentPRTTask.patient.dob | moment("MM/DD/YYYY") }}</h6>
                  <h6 v-else>N/A</h6>
                </ion-col>
                <ion-col size="auto" class="pad-lr-twenty">
                  <div class="text-muted">
                    ID:
                  </div>
                  <h6>{{ currentPRTTask.patient.mrn ? currentPRTTask.patient.mrn : "N/A" }}</h6>
                </ion-col>
                <ion-col size="auto" class="pad-lr-twenty">
                  <div class="text-muted">
                    Patient's Phone Number:
                  </div>
                  <h6>{{ formatPhoneNumber(currentPRTTask.patient.phone) }}</h6>
                </ion-col>
              </ion-row>
            </div>
          </ion-card>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-card color="white" class="no-box-shadow">
            <div class="pad-ten">
              <ion-row class="valign" v-if="currentPRTTask">
                <ion-col size="auto" class="pad-lr-twenty">
                  <div class="text-muted margin-bottom-five">
                    Requires Response:
                  </div>
                  <div class="pill text-bold text-center" :class="{ 'green-pill': currentPRTTask.closed, 'yellow-pill': requiresResponse == 'YES' }">{{ requiresResponse }}</div>
                </ion-col>
                <ion-col size="auto" class="pad-lr-twenty" v-if="!currentPRTTask.closed">
                  <div class="text-muted margin-bottom-five">Time Open:</div>
                  <div class="pill yellow-pill text-bold text-center">
                    <TimeSince :startdate="currentPRTTask.opened"></TimeSince>
                  </div>
                </ion-col>
                <ion-col size="auto" class="pad-lr-twenty" v-else-if="currentPRTTask.opened && currentPRTTask.closed">
                  <div class="text-muted margin-bottom-five">Time Open:</div>
                  <div class="pill text-bold text-center">
                    ---
                  </div>
                </ion-col>
                <ion-col size="auto" class="pad-lr-twenty">
                  <div class="text-muted margin-bottom-five">In Progress:</div>
                  <div class="pill text-bold text-center" :class="{ 'green-pill': currentPRTTask.inProgress }">
                    {{ inProgressString }}
                  </div>
                </ion-col>
                <ion-col></ion-col>
                <ion-col size="auto" class="pad-ten margin" v-if="!currentPRTTask.closed && !currentPRTTask.inProgress">
                  <button @click="setStatus('inProgress')" class="prime-button button-primary">
                    Mark In Progress
                  </button>
                </ion-col>
                <ion-col size="auto" class="pad-ten margin" v-if="!currentPRTTask.closed && currentPRTTask.inProgress">
                  <button @click="setStatus('notInProgress')" class="prime-button button-secondary">
                    Mark Not In Progress
                  </button>
                </ion-col>
                <ion-col size="auto" class="pad-ten" v-if="!currentPRTTask.closed">
                  <button @click="setStatus('complete')" class="prime-button button-primary">
                    Mark Complete
                  </button>
                </ion-col>
                <ion-col size="auto" class="pad-ten" v-else>
                  <button @click="setStatus('reOpen')" class="prime-button button-secondary">
                    Re-Open Task
                  </button>
                </ion-col>
              </ion-row>
            </div>
          </ion-card>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="6">
          <ion-card color="white" class="pad-twenty prime-internal-scoll-card no-box-shadow" v-if="chatTranscript">
            <div v-if="isLoadingMessages" class="text-center pad-ten full-height">
              <div class="centerItems text-muted display-flex valign full-height full-width">
                <ion-spinner name="lines" class="spinner-large valign"></ion-spinner>
              </div>
            </div>
            <div v-else>
              <h5>Complete transcript of conversation:</h5>
              <div id="chatTranscript" class="card-scroll-vert">
                <ChatBubble v-for="message in chatTranscript.messages" :key="message.id" v-bind="{ message }" />
              </div>
              <button @click="copyChatTranscript" class="prime-button button-secondary button-block margin-top-ten">
                <ion-icon name="ios-copy"></ion-icon>
                Copy Chat Transcript
              </button>
              <div v-if="$can(I.ACCESS_CLIENT_PARTNER)">
                <button v-if="!currentPRTTask.clientPartnerReviewed" @click="markAsReviewed()" class="prime-button button-primary button-block margin-top-ten">
                  <ion-icon name="md-checkmark"></ion-icon>
                  Mark as Reviewed
                </button>
                <div v-else-if="currentPRTTask.reviewedAt" class="grey-well margin-top-ten text-center text-bold center-center">Marked as Reviewed on {{ currentPRTTask.reviewedAt | moment("M/D/YYYY - h:mmA") }}</div>
              </div>
            </div>
            <div v-if="showMarkAgreed" class="text-center margin-top-ten">
              <strong>
                <small>
                  Did our system misunderstand the patient's agreement to enroll?
                  <span class="mark-agreed cursor-pointer" @click="openConfirmAgreedModal">Mark as Agreed</span>
                </small>
              </strong>
            </div>
          </ion-card>
        </ion-col>
        <ion-col size="6">
          <ion-card color="white" class="pad-twenty prime-internal-scoll-card no-box-shadow">
            <div v-if="isLoadingComments" class="column-stretch-body centerItems text-center text-muted display-flex valign full-height">
              <ion-spinner name="lines" class="spinner-large valign" color="primary"></ion-spinner>
            </div>
            <div v-else>
              <h5>Task Comments:</h5>
              <div class="center-center" v-if="commentsAsChat.length < 1">
                <h5 class="text-muted">No comments to display</h5>
              </div>
              <div v-else class="card-scroll-vert">
                <ChatBubble v-for="comment in commentsAsChat" :key="comment.id" :message="comment" orientation="full" color="none" :actions="showCommentActions(comment)" />
              </div>
              <div class="divider" />
              <TextAreaInput v-model="commentBody" placeholder="Leave a comment..." color="secondary" @save="saveComment" :isSaving="isSavingComment" />
              <ion-row v-if="commentError" class="pad-top-ten">
                <div :class="{ 'danger-well': commentError }" class="margin-bottom-ten notifications-well full-width">
                  {{ commentError }}
                </div>
              </ion-row>
            </div>
          </ion-card>
        </ion-col>
      </ion-row>
    </ion-content>
  </PrimePage>
</template>
<script>
import { addIcons } from "ionicons";
import { more, arrowBack, arrowForward, arrowUp, copy, checkmark } from "ionicons/icons";
import IconChevronDouble from "@/components/Global/Icons/IconChevronDouble";
import IconChevronDoubleBack from "@/components/Global/Icons/IconChevronDoubleBack";
import { send as httpSend } from "@/services/Api";
import ChatBubble from "@/components/Chat/ChatBubble";
import TimeSince from "@/components/Global/TimeSince";
import TextAreaInput from "@/components/Global/TextAreaInput";
import PopoverCommentActions from "@/components/TaskDetails/PopoverCommentActions";
import PopoverCopyToClipboard from "@/components/TaskDetails/PopoverCopyToClipboard";
import ModalEditTextArea from "@/components/Global/ModalEditTextArea";
import ModalConfirm from "@/components/Global/ModalConfirm";
import formatPhoneNumber from "@/mixins/FormatPhoneNumberForUI";
import PrimePage from "@/components/Global/PrimePage";

addIcons({
  "md-more": more.md,
  "ios-arrow-back": arrowBack.ios,
  "ios-arrow-forward": arrowForward.ios,
  "md-arrow-up": arrowUp.md,
  "ios-copy": copy.md,
  "md-checkmark": checkmark.md
});

export default {
  name: "TaskDetailsPRT",
  components: {
    IconChevronDouble,
    IconChevronDoubleBack,
    ChatBubble,
    TimeSince,
    TextAreaInput,
    PrimePage
  },
  mixins: [formatPhoneNumber],
  data() {
    return {
      currentPRTTask: undefined,
      loading: false,
      chatTranscript: undefined,
      comments: undefined,
      commentsAsChat: undefined,
      isLoading: false,
      isLoadingMessages: false,
      isLoadingComments: false,
      isSavingComment: false,
      commentBody: undefined,
      commentError: undefined,
      selectedComment: {}
    };
  },
  mounted() {
    this.getTask(true, true, true, undefined);
  },
  computed: {
    showGreyPill() {
      return this.taskStatus === "Closed" || this.taskStatus === "Inactive";
    },
    showTimeOpen() {
      return this.taskStatus !== "Inactive" && !this.currentPRTTask.closed;
    },
    makePcpName() {
      return (this.currentPRTTask.provider.title ? this.currentPRTTask.provider.title + " " : "") + (this.currentPRTTask.provider.firstName ? this.currentPRTTask.provider.firstName + " " : "") + (this.currentPRTTask.provider.lastName ? this.currentPRTTask.provider.lastName : "");
    },
    makeName() {
      return (this.currentPRTTask.patient.lastName ? this.currentPRTTask.patient.lastName + ", " : "") + (this.currentPRTTask.patient.firstName ? this.currentPRTTask.patient.firstName : "");
    },
    taskStatus() {
      let status;

      if (this.currentPRTTask.closed) {
        status = "Closed";
      } else if (this.currentPRTTask.inProgress == true) {
        status = "In Progress";
      } else if (!this.currentPRTTask.inProgress && !this.currentPRTTask.assigned) {
        status = "Inactive";
      } else if (!this.currentPRTTask.closed) {
        status = "Open";
      }

      return status;
    },
    requiresResponse() {
      let requiresResponse;
      if (this.currentPRTTask.closed) {
        requiresResponse = "COMPLETED";
      } else if (this.currentPRTTask.inProgress || this.currentPRTTask.assigned) {
        requiresResponse = "YES";
      } else {
        requiresResponse = "NO";
      }
      return requiresResponse;
    },
    inProgressString() {
      return this.currentPRTTask.inProgress ? "YES" : "NO";
    },
    showMarkAgreed() {
      const { beneficiaryOptInStatus, beneficiaryOptOutStatus } = this.currentPRTTask.patient;
      return !beneficiaryOptInStatus && !beneficiaryOptOutStatus;
    }
  },
  methods: {
    getTask: function(showLoader, refreshChat, refreshComments, action) {
      showLoader ? (this.isLoading = true) : "";
      const method = "get";

      const taskId = this.$route.params.outreach_id;
      let path;

      if (action == "nextOpenTask") {
        path = document.config.SITNextOpenTask + taskId;
      } else if (action == "nextTask") {
        path = document.config.SITNextTask + taskId;
      } else if (action == "prevTask") {
        path = document.config.SITPreviousTask + taskId;
      } else if (action == "prevOpenTask") {
        path = document.config.SITPreviousOpenTask + taskId;
      } else {
        path = document.config.getTaskById + taskId;
      }

      httpSend({ path, method, authToken: true })
        .then(result => {
          this.currentPRTTask = result.data;
          refreshChat ? this.getChatTranscript(result.data.interactionId) : "";
          refreshComments ? this.getComments(result.data.id) : "";

          if (result.data.id != taskId) {
            this.$router.push({
              name: "TaskDetailsPRT",
              params: { id: result.data.id }
            });
          }
        })
        .catch(error => {
          if (action == "nextOpenTask" || action == "nextTask") {
            if (error.message == "Request failed with status code 404") {
              this.$ionic.toastController
                .create({
                  header: "No more open tasks",
                  message: "You have reached the end of your task list",
                  duration: 4000,
                  position: "top"
                })
                .then(m => m.present());
            }
          } else if (action == "prevTask" || action == "prevOpenTask") {
            if (error.message == "Request failed with status code 404") {
              this.$ionic.toastController
                .create({
                  header: "No more tasks",
                  message: "You have reached the beginning of your task list",
                  duration: 4000,
                  position: "top"
                })
                .then(m => m.present());
            }
          } else {
            this.catchError(error, "Could not get task. Please try again later.");
          }
        })
        .finally(() => (this.isLoading = false));
    },
    setStatus(status) {
      // this.loading = true;

      if (status == "inProgress") {
        this.currentPRTTask.inProgress = true;
        this.currentPRTTask.closed = null;
      } else if (status == "notInProgress") {
        this.currentPRTTask.inProgress = false;
      } else if (status == "reOpen") {
        this.currentPRTTask.closed = null;
      } else if (status == "complete") {
        this.currentPRTTask.closed = new Date();
        this.currentPRTTask.inProgress = false;
      }
      this.updateTask();
    },
    markAsReviewed() {
      this.currentPRTTask.clientPartnerReviewed = true;
      this.currentPRTTask.reviewedAt = new Date();
      this.updateTask();
    },
    updateTask() {
      const method = "put";
      const path = document.config.SITUpdateTask;
      const payload = this.currentPRTTask;

      httpSend({ path, method, payload, authToken: true })
        .then(() => {
          this.$ionic.toastController
            .create({
              header: "Success",
              message: "Task was successfully updated.",
              duration: 4000,
              color: "primary",
              mode: "ios"
            })
            .then(m => m.present());

          this.getTask(false, false, false, undefined);
        })
        .catch(error => {
          this.catchError(error, "Could not set task status. Please try again later.");
        })
        .finally(() => (this.loading = false));
    },
    getChatTranscript(id) {
      this.isLoadingMessages = true;
      const method = "get";
      const path = document.config.messageApi + id + "/messages";

      httpSend({ path, method, authToken: true })
        .then(result => {
          this.chatTranscript = result.data;
        })
        .catch(error => {
          this.catchError(error, "Could not get chat transcript. Please try again later.");
        })
        .finally(() => (this.isLoadingMessages = false));
    },
    saveComment(commentBody) {
      this.commentError = false;
      if (!commentBody) {
        this.commentError = "Please enter comment text";
        return;
      }

      this.isSavingComment = true;
      const method = "post";
      const path = document.config.SITUpdateTaskComment;

      let payload = {
        body: commentBody,
        taskId: this.currentPRTTask.id
      };

      httpSend({ path, method, payload, authToken: true })
        .then(result => {
          this.comments = result.data;
          this.getComments(this.$route.params.id);
          this.selectedComment = {};
        })
        .catch(error => {
          this.catchError(error, "Could not save comment. Please try again later.");
        })
        .finally(() => (this.isSavingComment = false));
    },
    getComments(id) {
      this.isLoadingComments = true;
      const method = "get";
      const path = document.config.SITGetTaskComments + id;

      httpSend({ path, method, authToken: true })
        .then(result => {
          this.comments = result.data;
          this.commentsAsChat = result.data.map(comment => ({
            id: comment.id,
            commenter: comment.commenter,
            body: comment.body,
            created_at: comment.time,
            title: comment.commenterTitle,
            first_name: comment.commenterFirstName,
            last_name: comment.commenterLastName,
            suffix: comment.commenterSuffix
          }));
        })
        .catch(error => {
          this.catchError(error, "Could not get comments. Please try again later.");
        })
        .finally(() => (this.isLoadingComments = false));
    },
    editComment(newComment) {
      this.commentError = false;
      if (!newComment) {
        this.commentError = "Please enter comment text";
        return;
      }

      const method = "put";
      const path = document.config.SITUpdateTaskComment;

      const payload = {
        ...this.selectedComment,
        body: newComment
      };
      this.isSavingComment = true;

      httpSend({ path, method, payload, authToken: true })
        .then(() => {
          this.getComments(this.$route.params.id);
          this.selectedComment = undefined;
        })
        .catch(error => {
          this.catchError(error, "Could not edit comment. Please try again later.");
        })
        .finally(() => (this.isSavingComment = false));
    },
    deleteComment(id) {
      const method = "delete";
      const path = document.config.SITUpdateTaskComment;

      const comment = this.comments.find(comment => comment.id === id);
      const payload = {
        id: comment.id
      };

      httpSend({ path, method, payload, authToken: true })
        .then(() => {
          this.getComments(this.$route.params.id);
        })
        .catch(error => {
          this.catchError(error, "Could not delete comment. Please try again later.");
        });
    },
    showCommentActions(comment) {
      const prof = this.$store.getters["currentProfessional/get"].professional;
      if (prof.id !== comment.commenter) {
        return null;
      }
      return {
        component: PopoverCommentActions,
        events: {
          edit: () => {
            this.selectedComment = this.comments.find(c => c.id == comment.id);
            this.openEditCommentModal(comment.body);
          },
          delete: () => {
            this.deleteComment(comment.id);
          }
        }
      };
    },
    openEditCommentModal(comment) {
      return this.$ionic.modalController
        .create({
          component: ModalEditTextArea,
          componentProps: {
            propsData: {
              title: "Edit Comment",
              instructions: "Edit your comment here and then click 'Save Edit' below.",
              value: comment,
              onSave: this.editComment,
              validators: [value => (!value ? "Please enter comment text" : null)]
            }
          }
        })
        .then(m => m.present());
    },
    openConfirmAgreedModal() {
      this.$ionic.modalController
        .create({
          component: ModalConfirm,
          componentProps: {
            propsData: {
              title: "Confirm Patient Agreement",
              text: 'By clicking "Confirm" below, I confirm that this patient\'s written response indicates that they agree to enroll in the program. This should only be used when the patient has explicitly texted that they agree, and the system was unable to understand their consent. If this is not clearly indicated in the transcript, do NOT mark the patient as having agreed.',
              onConfirm: this.markAgreed
            }
          }
        })
        .then(m => m.present());
    },
    markAgreed() {
      const { beneficiaryAccountId: beneficiaryId, id } = this.currentPRTTask;
      const method = "put";
      const path = `${document.config.SITOptIn}?beneficiaryAccId=${beneficiaryId}&taskId=${id}&programId=2`;

      httpSend({ path, method, authToken: true })
        .then(() => {
          this.getTask(true, true, true);
        })
        .catch(error => {
          this.catchError(error, "Failed to Opt-in patient. Please try again later.");
        });
    },
    copyChatTranscript(evt) {
      this.$ionic.popoverController
        .create({
          component: PopoverCopyToClipboard,
          event: evt,
          mode: "ios",
          cssClass: "popover-width-auto",
          componentProps: {
            propsData: {
              messages: this.chatTranscript.messages,
              comments: this.commentsAsChat
            }
          }
        })
        .then(p => p.present());
    },
    catchError(error, message) {
      this.$ionic.toastController
        .create({
          header: message,
          message: error,
          duration: 5000,
          position: "top"
        })
        .then(m => m.present());
      khanSolo.log(error);
    }
  }
};
</script>

<style scoped>
.prime-comment-list-item {
  position: relative;
  margin: 5px 0;
}

.pill {
  display: inline-block;
  width: 100%;
  border-radius: 100px;
  padding: 6px 15px;
  background-color: #e6e6e6;
  color: #656e78;
}

.yellow-pill {
  background-color: #fad265;
  color: #4f411c;
}

.grey-pill {
  background-color: #aeaeae;
  color: #2e2e2e;
}

.green-pill {
  background-color: #579400;
  color: #ffffff;
}

.icon-back {
  height: 20px;
  width: 20px;
}
/* Revisit this css, not the best for screen scroll */

.prime-patient-view {
  padding-bottom: 80px;
}

.card-scroll-vert {
  overflow-y: scroll;
  /* height: 500px; */
}

ion-content {
  --offset-bottom: -75px;
}

.divider {
  border-top: 2px solid var(--ion-background-color);
  margin: 20px -20px 0;
}

.no-box-shadow {
  box-shadow: none !important;
}

.mark-agreed {
  color: var(--ion-color-primary);
}
</style>
