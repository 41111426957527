export default {
  methods: {
    formatPhoneNumber: function(num) {
      const rawNumbers = num.replace("+1", "").split("");
      const areaCode = rawNumbers.slice(0, 3).join("");
      const areaCodeParens = "(" + areaCode + ")";
      const prefix = rawNumbers.slice(3, 6).join("");
      const lineNumber = rawNumbers.slice(6, 10).join("");
      const finalFormat = areaCodeParens + " " + prefix + "-" + lineNumber;

      return finalFormat;
    }
  }
};
