<template>
  <div class="prime-modal">
    <ion-header>
      <ion-toolbar>
        <h5 slot="start">{{ title }}</h5>
        <ion-button slot="end" mode="ios" @click="closeModal()" expand="full" color="medium" fill="clear"><ion-icon name="md-close"></ion-icon></ion-button>
      </ion-toolbar>
    </ion-header>
    <ion-content class="prime-modal-content">
      <p class="text">{{ text }}</p>
    </ion-content>
    <ion-footer>
      <ion-grid>
        <ion-row>
          <ion-col>
            <button @click="closeModal()" class="prime-button button-secondary button-block">
              Cancel
            </button>
          </ion-col>
          <ion-col>
            <button @click="confirm()" class="prime-button button-block" :class="{ 'button-pending': isSaving }">
              <ion-spinner name="dots" duration="1200" color="white"></ion-spinner>
              <span>Confirm</span>
            </button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-footer>
  </div>
</template>

<script>
export default {
  name: "ModalEditTextArea",
  props: {
    title: String,
    text: String,
    isSaving: Boolean,
    onConfirm: Function
  },
  methods: {
    closeModal() {
      this.$ionic.modalController.dismiss();
    },
    confirm() {
      this.onConfirm && this.onConfirm();
      this.$ionic.modalController.dismiss();
    }
  }
};
</script>

<style scoped>
.text {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
}
</style>
